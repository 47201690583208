import { useEffect } from 'react'
import { taboolaLog } from '../utils'
import { useRouteChange } from '../contexts'

export const taboolaFlush = (ms = 100) => {
  setTimeout(() => {
    if (window._taboola) {
      taboolaLog(['_taboola.push({ flush: true })', ms])
      window._taboola && window._taboola.push({ flush: true })
    } else {
      taboolaFlush()
    }
  }, ms)
}
export const TaboolaLoader = () => {
  const change = useRouteChange()

  useEffect(() => {
    if (
      change.length > 0 &&
      window._newsroom &&
      window.location.pathname === '/'
    ) {
      window._newsroom.push({ pageTemplate: 'home' })
      window._newsroom.push({ pageDashboard: 'home' })
      window._newsroom.push('auditClicks')
      window._newsroom.push('trackPage')
    }
    if (change.length < 2 || !window._taboola) {
      return
    }
    taboolaLog({ msg: 'newPageLoad' })
    window?._taboola.push({ notify: 'newPageLoad' })
    setTimeout(taboolaFlush, 500)
  }, [change])

  return null
}
