import styled from 'styled-components'
import { device } from '../../../styles/GlobalVariables'

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background: #000;
  justify-content: start;
  bottom: 0;
  gap: 20px;
  align-items: center;
  cursor: pointer;
  max-width: calc(100% - 60px);
  width: auto;
  height: 36px;
  position: absolute;
  right: 0;

  ${device.medium} {
    height: 56px;
    justify-content: end;
    gap: 30px;
    max-width: 616px;
    bottom: unset;

    &.full-screen {
      height: 90px;
      max-width: 780px;
    }
  }
`
export const ReplayButton = styled.div`
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  cursor: pointer;
  background: #000;
  position: absolute;
  left: 15px;
  bottom: 0;
  display: flex;

  ${device.medium} {
    left: 20px;
    top: 12px;

    &.full-screen {
      width: 90px;
      height: 90px;

      & img.full-screen {
        width: 47px;
        height: 42px;
        position: absolute;
        left: 20px;
        top: 22px;
      }
    }
  }
`
export const Title = styled.div`
  text-align: right;
  direction: rtl;
  color: #fff;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  left: 15px;
  font-size: 14px;

  ${device.medium} {
    font-weight: 500;
    max-width: calc(100% - 80px);
    white-space: break-spaces;
    overflow: visible;

    &.full-screen {
      font-size: 24px;
    }
  }
`
export const Poster = styled.img`
  ${device.medium} {
    width: 96px;

    &.full-screen {
      width: 160px;
    }
  }

  width: 64px;
  aspect-ratio: 16 / 9;
`

export const BottomWrapper = styled.div`
  ${device.medium} {
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 100%;
    bottom: 82px;

    &.full-screen {
      top: 100px;
      position: relative;
    }
  }

  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 100%;
  position: absolute;
  bottom: 8%;

  &.full-screen {
    top: ${props => (props.theme.isMobileFullScreen ? 'auto' : '300px')};
    bottom: 10px;
    position: absolute;
  }

  ${device.small} {
    &.full-screen {
      top: auto;
      bottom: 12vh;
    }
  }
`
export const NextVideoTimerWrapper = styled.div`
  @media screen and (max-width: 768px) {
    &.full-screen {
      width: 100vw;
      height: 100vh;
      background: black;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 100;
    }
  }
`
export const CounterLogoBackGround = styled.div`
  background: #00104a;
  background-size: cover;
  position: absolute;
  width: 100%;
  aspect-ratio: 16/9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
  top: 50%;
  transform: translateY(-50%);

  &.full-screen {
    max-height: 100vh;
  }

  ${device.small} {
    top: 0;
    transform: none;
  }
`
export const NextVideoWrapper = styled.div`
  position: absolute;
  background: black;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  left: 0;
`
