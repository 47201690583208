import { useEffect, useState } from 'react'
import { loadScriptOnce } from '../../utils'
import { isNativeApp } from '../../client'

/** Loads taboola scripts and returns a value when it is ready */
const getTaboolaLoader = () => {
  let scriptType = 'tb_loader_script',
    loaderUrl = 'https://cdn.taboola.com/libtrc/reshet-reshet/loader.js',
    os = 'Web',
    taboolaMobilePubName = ''
  const userAgent = navigator.userAgent || navigator.vendor // || window.hasOwnProperty("opera") ? window.opera : {}

  if (isNativeApp()) {
    if (/android/i.test(userAgent)) {
      taboolaMobilePubName = 'reshet-13tvappandroid'
      scriptType = 'tb-mobile-loader-script'
      loaderUrl =
        'https://cdn.taboola.com/libtrc/reshet-13tvappandroid/mobile-loader.js'
      os = 'Android'
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      taboolaMobilePubName = 'reshet-13tvappios'
      scriptType = 'tb-mobile-loader-script'
      loaderUrl =
        'https://cdn.taboola.com/libtrc/reshet-13tvappios/mobile-loader.js'
      os = 'iOS'
    }
  }
  window.taboolaMobilePubName = taboolaMobilePubName
  return { os, scriptType, loaderUrl, taboolaMobilePubName }
}

interface TaboolaInfo {
  isTaboolaReady: boolean
  taboolaPubName: string
  os: string
  scriptLoad: 'ready' | 'failed' | null | true | void
}

export function useTaboola(): TaboolaInfo {
  const [info, setInfo] = useState<TaboolaInfo>({
    isTaboolaReady: false,
    taboolaPubName: '',
    os: '',
    scriptLoad: null,
  })
  useEffect(() => {
    window._taboola = window._taboola || []
    window._newsroom = window._newsroom || []
    let unmounted = false
    ;(async () => {
      const props = getTaboolaLoader()
      const success = await loadScriptOnce({
        src: props.loaderUrl,
        id: props.scriptType,
      })
      if (unmounted) {
        return
      }
      setInfo({
        isTaboolaReady: success === 'ready',
        taboolaPubName: props.taboolaMobilePubName,
        os: props.os,
        scriptLoad: success,
      })
    })()
    return () => {
      unmounted = true
    }
  }, [])
  return info
}
