import {
  LdsDefault,
  StyledKalturaLoader,
  StyledKalturaLoaderDot,
} from '../Kaltura/Kaltura.styles'
import cx from 'classnames'

export const Loader = ({ showLoader }: { showLoader: boolean }) => {
  if (!showLoader) {
    return null
  }
  return (
    <StyledKalturaLoader
      className={cx(['loaderWrpKaltura', showLoader ? 'show' : 'hide'])}
    >
      <LdsDefault>
        {Array.from(Array(12).keys()).map(i => (
          <StyledKalturaLoaderDot key={i} theme={{ pos: i + 1 }} />
        ))}
      </LdsDefault>
    </StyledKalturaLoader>
  )
}
