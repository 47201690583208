import React, { useEffect, useState } from 'react'
import {
  TimerContainer,
  TimerSvg,
  TimerG,
  TimerCircle,
  TimerPath,
  TimerLabel,
  TimerLogoImage,
  TimerBottomText,
  TimerFlexContainer,
} from './CounterLogo.styles'

type Props = {
  CounterLogoWidth?: number | string
  CounterLogoHeight?: number | string
  pathColor?: string
  pathWidth?: number
  PathBackgroundColor?: string
  fontSize?: number | string
  fontColor?: string
  logoUrl?: string
  logoImageWidth?: number | string
  logoImageHeight?: number | string
  timeToRun: number
  timerBottomText?: string
  timerBottomTextFontSize?: number
  timerBottomTextFontColor?: string
}

const CounterLogo = ({
  CounterLogoWidth,
  CounterLogoHeight,
  pathColor,
  pathWidth,
  logoUrl,
  timeToRun,
  fontSize,
  fontColor,
  PathBackgroundColor,
  logoImageWidth,
  logoImageHeight,
  timerBottomText,
  timerBottomTextFontSize,
  timerBottomTextFontColor,
}: Props) => {
  const [timeLeft, setTimeLeft] = useState(timeToRun)

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimeLeft(prevTimeLeft => {
        if (prevTimeLeft === 0) {
          clearInterval(timerInterval)
          return 0
        }
        return prevTimeLeft - 1
      })
    }, 1000)

    return () => clearInterval(timerInterval)
  }, [])

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60)
    const seconds = time % 60

    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
  }

  const calculateTimeFraction = () => {
    const rawTimeFraction = timeLeft / timeToRun
    return rawTimeFraction - (1 / timeToRun) * (1 - rawTimeFraction)
  }

  const setCircleDasharray = () => {
    const circleDasharray = `${(calculateTimeFraction() * 283).toFixed(0)} 283`
    return circleDasharray
  }

  return (
    <TimerFlexContainer>
      <TimerContainer
        theme={{ width: CounterLogoWidth, height: CounterLogoHeight }}
      >
        <TimerSvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'>
          <TimerG>
            <TimerCircle
              theme={{
                backgroundPathColor: PathBackgroundColor,
                pathWidth: pathWidth,
              }}
              cx='50'
              cy='50'
              r='45'
            ></TimerCircle>
            <TimerPath
              theme={{ runStrokeColor: pathColor, pathWidth: pathWidth }}
              strokeDasharray={setCircleDasharray()}
              d='
                  M 50, 50
                  m -45, 0
                  a 45,45 0 1,0 90,0
                  a 45,45 0 1,0 -90,0
                '
            />
          </TimerG>
        </TimerSvg>
        <TimerLabel
          theme={{
            fontSize: fontSize,
            fontColor: fontColor,
            width: CounterLogoWidth,
            height: CounterLogoHeight,
          }}
        >
          {logoUrl ? (
            <TimerLogoImage
              theme={{
                logoImageWidth: logoImageWidth,
                logoImageHeight: logoImageHeight,
              }}
              src={logoUrl}
            />
          ) : (
            formatTime(timeLeft)
          )}
        </TimerLabel>
      </TimerContainer>
      <TimerBottomText
        theme={{
          fontSize: timerBottomTextFontSize,
          fontColor: timerBottomTextFontColor,
        }}
        children={timerBottomText ?? ''}
      />
    </TimerFlexContainer>
  )
}

export default CounterLogo
