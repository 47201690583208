import styled, { css } from 'styled-components'
import { playerConfig } from '../Helpers/constants'

const { MobileBreakPoint, inWebView } = playerConfig

export const dvrTime = () => css`
  .dvr-time {
    height: 22px;
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.7);
    font-size: 13px;
    font-weight: bold;
    line-height: 22px;
    pointer-events: none;
    text-align: center;
  }
`
export const ButtonClosePIP = styled.div`
  position: absolute;
  margin-top: 5px;
  margin-right: 5px;
  cursor: pointer;
  border: none;
  border-radius: 30px;
  font-size: 18px;
  font-weight: bold;
  line-height: 0;
  padding: 8px 5px;
  background: transparent;
  z-index: 99999999;
  top: 13px;
  left: 7px;
  color: white;

  &:before {
    content: 'x';
    font-weight: 600;
    font-family: Arial, sans-serif;
  }

  &:hover {
    color: red;
    border-color: red;
  }
`

const playkitGuiArea = styled.div`
  .playkit-gui-area {
    .playkit-spinner {
      display: none;
    }
    .playkit-share-icons {
      button,
      a {
        display: none;
      }
      button.playkit-btn-rounded[title*='embed'],
      button[aria-label='Get embed code'] {
        display: inline-block;
      }
    }

    .playkit-control-button:hover,
    .playkit-icon:hover {
      filter: brightness(0.5) sepia(1) saturate(10000%);
    }

    .playkit-bottom-bar {
      background: rgba(0, 0, 0, 0.44);
      position: relative;
      .playkit-live-tag {
        border: 2px solid red;
        color: white !important;
        margin-left: 0;
      }

      .playkit-live-tag.playkit-non-live-playhead {
        position: absolute;
        top: -38px;
        left: 2%;
        cursor: pointer;
        color: white !important;
        background: red;
        border: none;
        margin: 0;
      }

      .playkit-volume-control-bar .playkit-progress {
        background-color: red;
      }

      .playkit-control-button-container {
        margin-right: 15px;
        margin-left: 0;

        &.playkit-control-picture-in-picture {
          display: ${() => inWebView && 'none'};
        }
      }
    }
  }
`

const KalturaPlayerContainer = styled(playkitGuiArea)`
  .kaltura-player-container {
    &.pictureinpicturemode {
      position: fixed;
      z-index: 99999999999;
      bottom: 20px;
      right: 20px;
      transform: translateY(100%);
      width: 401px;
      height: 225.562px;
      animation: fade-in-up 0.25s ease forwards;
      .playkit-bottom-bar {
        button[aria-label='נגן צף'] {
          display: none;
        }
        button[aria-label='הגדרות'] {
          display: none;
        }
      }
      @media (max-width: 978px) {
        width: 233px;
        height: 132.562px;
        right: 0;
        bottom: 0;
      }

      .picInPicClose {
        position: absolute;
        margin-top: 5px;
        margin-right: 5px;
        cursor: pointer;
        border: none;
        border-radius: 30px;
        font-size: 18px;
        font-weight: bold;
        line-height: 0px;
        padding: 8px 5px;
        background: transparent;
        z-index: 99999999;
        top: 13px;
        left: 7px;
        color: white;

        &:before {
          content: 'x';
          font-weight: 600;
          font-family: Arial, sans-serif;
        }

        &:hover {
          color: red;
          border-color: red;
        }
      }
    }

    &.playkit-in-browser-fullscreen-mode {
      z-index: 99999999 !important;
    }

    .playkit-player {
      & .playkit-size-ty {
        .playkit-bottom-bar {
          .playkit-seek-bar {
            width: 100%;
          }
        }
      }
      &.playkit-size-xs {
        .playkit-gui-area {
          .playkit-bottom-bar {
            .playkit-control-button-container {
              margin-right: 10px;
            }
          }
        }
      }

      &.playkit-size-sm {
        .playkit-gui-area {
          .playkit-bottom-bar {
            .playkit-control-button-container {
              margin-right: 6px;
              .playkit-control-button {
                width: 25px;
                height: 32px;
              }
            }
          }
        }
      }

      &.playkit-size-md {
        .playkit-gui-area {
          .playkit-bottom-bar {
            .playkit-control-button-container {
              margin-right: 8px;
            }
          }
        }
      }

      &.playkit-fullscreen {
        cursor: default;
        &:not(.playkit-hover) {
          cursor: none;
        }
      }
    }

    @media (min-width: 801px) {
      .playkit-gui-area {
        .playkit-bottom-bar {
          .playkit-progress-bar {
            .playkit-progress {
              background-color: red;
            }
          }
          .playkit-seek-bar {
            margin-right: -16px;
            margin-left: -16px;
            padding-top: 0;

            ${dvrTime()}
          }
        }
      }

      .playkit-player:not(.playkit-fullscreen) {
        .playkit-player-gui {
          .playkit-gui-area {
            .playkit-bottom-bar {
              opacity: 0;
              visibility: hidden;
              .playkit-seek-bar {
                margin-right: -16px;
                margin-left: -16px;
                padding-top: 0;
              }
            }
          }
        }
      }

      &:hover {
        .playkit-player:not(.playkit-fullscreen) {
          &:not(.playkit-hover) {
            .playkit-gui-area {
              .playkit-bottom-bar {
                opacity: 0;
                visibility: hidden;
              }
            }
          }
          .playkit-gui-area {
            .playkit-bottom-bar {
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
    }

    @media (max-width: 425px) {
      .playkit-gui-area {
        .playkit-overlay-contents {
          padding: 30px 20px 30px 20px;
        }
      }
    }
  }
`

export const KlaturaPlayerWrp = styled.div`
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;

  @keyframes fade-in-up {
    0% {
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  &.loadenOn {
    .playkit-gui-area {
      visibility: hidden;
    }
  }

  .screenReaderOnly {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
  }

  .img-wrp.kalturaImgErr {
    z-index: 99999;
    width: 100%;
    position: absolute;
    right: 0;

    img {
      right: 0;
    }
  }

  .adFlag {
    font-weight: 700;
    opacity: 0.8;
    color: white;
    background-color: rgb(72, 87, 101);
    padding: 4px 10px;
    right: 0;
    top: 0;
    z-index: 2;
    position: absolute;
    font-size: 17px;
    @media (max-width: ${MobileBreakPoint}px) {
      padding: 3px 6px;
      margin-right: 0;
      font-size: 14px;
    }
    display: ${props => (props.theme.showingAd ? 'block;' : 'none;')};
  }
`

export const KlaturaPlayerDiv = styled(KalturaPlayerContainer)`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  direction: ltr;
  @media screen and (max-width: 450px) {
    div.playkit-volume-control-bar {
      display: none;
    }
  }
`

export const StyledKalturaLoader = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &.hide {
    display: none;
  }
  &.show {
    display: flex;
  }

  @keyframes lds-default {
    0%,
    20%,
    80%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
  }
`
const dot = (child: number) => {
  switch (child) {
    case 1:
      return css`
        animation-delay: 0s;
        top: 37px;
        left: 66px;
      `
    case 2:
      return css`
        animation-delay: -0.1s;
        top: 22px;
        left: 62px;
      `
    case 3:
      return css`
        animation-delay: -0.2s;
        top: 11px;
        left: 52px;
      `
    case 4:
      return css`
        animation-delay: -0.3s;
        top: 7px;
        left: 37px;
      `
    case 5:
      return css`
        animation-delay: -0.4s;
        top: 11px;
        left: 22px;
      `
    case 6:
      return css`
        animation-delay: -0.5s;
        top: 22px;
        left: 11px;
      `
    case 7:
      return css`
        animation-delay: -0.6s;
        top: 37px;
        left: 7px;
      `
    case 8:
      return css`
        animation-delay: -0.7s;
        top: 52px;
        left: 11px;
      `
    case 9:
      return css`
        animation-delay: -0.8s;
        top: 62px;
        left: 22px;
      `
    case 10:
      return css`
        animation-delay: -0.9s;
        top: 66px;
        left: 37px;
      `
    case 11:
      return css`
        animation-delay: -1s;
        top: 62px;
        left: 52px;
      `
    case 12:
      return css`
        animation-delay: -1.1s;
        top: 52px;
        left: 62px;
      `
  }
}
export const LdsDefault = styled.div`
  z-index: 9;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
`
export const StyledKalturaLoaderDot = styled.div`
  position: absolute;
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;

  ${props => dot(props.theme.pos)};
`
export const RedStripDiv = styled.div`
  color: white;
  font-weight: 700;
  background-color: rgb(246, 21, 57);
  padding: 2px 4px;
  right: 0px;
  top: 0px;
  z-index: 2;
  max-width: 45%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  display: inline-block;

  @media all and (max-width: ${MobileBreakPoint}px) {
    margin-right: 8px;
    padding: 0px 3px;
    max-width: 100%;
    margin-top: 8px;
    margin-right: 0;
  }
`

export const YouMightAlsoLike = styled.div`
opacity: 0;
pointer-events: none;
transition: opacity .50s ease;
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.9));
vertical-align: middle;
z-index: 300;

.videoCube{
    transition: opacity .25s ease;
    &:nth-child(1){
      transition-delay: 0.10s;
    }
    &:nth-child(2){
      transition-delay: 0.20s;
    }
    &:nth-child(3){
      transition-delay: 0.30s;
    }
    &:nth-child(4){
      transition-delay: .40s;
    }
    &:nth-child(5){
      transition-delay: .5s;
    }
    &:nth-child(6){
      transition-delay: .6s;
    }
  }

  &.show{
    opacity: 1;
    pointer-events: initial;
    .videoCube{
      opacity: 1;
    }
  }
  ul{
    &:before{
      content: "אולי יעניין אותך";
      position: absolute;
      color: white;
      padding-right: 17px;
      height: 0;
      top: -1.4vw;
      right:0;
      font-size: 20px;
    }
    display: table;
    width: 85%;
    margin: auto;
    align-items: center;
    padding-top: ~'calc(3vw)';
    position: relative;
    top: 5%;
    li{
      display: table-cell;
      float: right;
      width: ~'calc((100% / 3) - 1px)';
      padding: 8px;
      .transition();
      &.init{
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
        -moz-transform: scale(0.9);
        -o-transform: scale(0.9);
        -ms-transform: scale(0.9);
        opacity: 0;
      }
      a{
        .img-wrp{}
        .title-container{
          margin-top: 4px;
          .title-wrp{
            .titleComp{
                width: 100%;
                font-size: 1.1vw;
                line-height: ~'calc(1.1vw * 1.11)';
                overflow: hidden;
                min-height: ~'calc(1.1vw * 1.11 * 2)';
                max-height: ~'calc(1.1vw * 1.11 * 2)';
            }
          }
          line-height: 1.11;
          color: #ffffff;
          text-decoration: none;
        }
      }
    }
    &:after{
      content: "";
      display: block;
      height: 0;
      clear: both;
    }
  }
  .thumbnails-c-new .videoCube .video-label-box .video-title{
    height: 48px;
  }
`

export const CloseTaboolaBtn = styled.button`
  position: absolute;
  right: 0;
  margin-top: 5px;
  margin-right: 5px;
  cursor: pointer;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 30px;
  font-size: 18px;
  font-weight: bold;
  line-height: 0;
  padding: 10px 5px;
  background: transparent;
`
