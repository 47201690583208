export const PlayButton = () => {
  return (
    <svg
      width='50'
      height='50'
      viewBox='0 0 50 50'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z'
        fill='#00104A'
        fillOpacity='0.1'
      />
      <path
        d='M24.9313 45C35.9391 45 44.8626 36.0457 44.8626 25C44.8626 13.9543 35.9391 5 24.9313 5C13.9235 5 5 13.9543 5 25C5 36.0457 13.9235 45 24.9313 45Z'
        fill='#00104A'
      />
      <path
        d='M34.486 24.1451C35.1276 24.5345 35.1276 25.4655 34.486 25.8549L21.7175 33.6045C21.0511 34.0089 20.1987 33.5291 20.1987 32.7496V17.2504C20.1987 16.4709 21.0511 15.9911 21.7175 16.3956L34.486 24.1451Z'
        fill='white'
      />
      <path
        d='M34.486 24.1451C35.1276 24.5345 35.1276 25.4655 34.486 25.8549L21.7175 33.6045C21.0511 34.0089 20.1987 33.5291 20.1987 32.7496V17.2504C20.1987 16.4709 21.0511 15.9911 21.7175 16.3956L34.486 24.1451Z'
        stroke='white'
      />
    </svg>
  )
}
