import React from 'react'
import styled from 'styled-components'
import { colors, device, fonts } from '../../../styles/GlobalVariables'
import { playerConfig } from '../Helpers/constants'

export const ImgWithRatio = styled.img<{
  ratio?: string
  kalturaClass?: string
  alt?: string
}>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 99;
  max-height: 540px;

  ${device.medium} {
    max-height: unset;
  }
`

export const StyledDiv = styled.div<React.CSSProperties>(props => ({
  minHeight: props.theme.minHeight,
  maxHeight: props.theme.maxHeight,
  width: props.theme.width,
  maxWidth: props.theme.maxWidth,
  display: props.theme.display,
  direction: props.theme.bacdirectionkground,
  padding: props.theme.padding,
  paddingTop: props.theme.paddingTop,
  paddingBottom: props.theme.paddingBottom,
  bottom: props.theme.bottom,
  color: props.theme.color,
  fontSize: props.theme.fontSize,
  verticalAlign: props.theme.verticalAlign,
  overflow: props.theme.overflow,
  overflowX: props.theme.overflowX,
  overflowY: props.theme.overflowY,
  lineHeight: props.theme.lineHeight,
  textAlign: props.theme.textAlign,
  position: props.theme.position,
  zIndex: props.theme.zIndex,
  right: props.theme.right,
  top: props.theme.top,
  left: props.theme.left,
  margin: props.theme.margin,
  marginTop: props.theme.marginTop,
  marginBottom: props.theme.marginBottom,
  borderTop: props.theme.borderTop,
  background: props.theme.background,
  backgroundColor: props.theme.backgroundColor,
  backgroundImg: props.theme.backgroundImg,
  border: props.theme.border,
  backgroundPosition: props.theme.backgroundPosition,
  backgroundRepeat: props.theme.backgroundRepeat,
  backgroundAttachment: props.theme.backgroundAttachment,
  backgroundSize: props.theme.backgroundSize,
  alignItems: props.theme.alignItems,
  flexDirection: props.theme.flexDirection,
  justifyContent: props.theme.justifyContent,
}))

export const VideoPlaceHolder = styled.div`
  padding-bottom: ${(9 / 16) * 100}%;
`

export const PlayerComp = styled.div`
  position: relative;
  @media screen and (max-width: ${playerConfig.MobileBreakPoint}px) {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  @media print {
    display: none;
  }
`

export const StyledPlayerComp = styled(PlayerComp)`
  height: 0;
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-bottom: calc(
    (9 / ${props => (props.theme.inEmbed ? 18 : 16)}) * 100%
  );
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .playkit-video-player,
  .playkit-black-cover,
  .playkit-poster,
  .kaltura-player-container {
    background: transparent;
    background-position: center;
    background-size: cover;
  }

  .playkit-fullscreen,
  .playkit-metadata-loaded {
    .playkit-video-player,
    .playkit-black-cover,
    .playkit-poster,
    .kaltura-player-container {
      background: black;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &.player-comp.live-player {
    .playkit-control-button[aria-label='שתף'] {
      display: none;
    }
  }

  ${props =>
    props.theme.poster
      ? `
            background-image: url(${props.theme.poster});
        `
      : ''};
`

export const WrapStyledPlayerComp = styled.div`
  position: relative;
  overflow: hidden;
  ${props =>
    props.theme.fromParser
      ? `
        margin: 40px 0;
    `
      : ''}
`

export const DescriptionAndCredit = styled.div`
  margin-top: 8px;
  direction: rtl;
  text-align: right;
  color: ${colors.gray};
  font-weight: ${fonts.weights.SemiBold};
`
