import styled from 'styled-components'

export const TimerFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  position: relative;
`
export const TimerContainer = styled.div`
  position: relative;
  width: ${props => (props.theme.width ? props.theme.width + 'px' : '150px')};
  height: ${props =>
    props.theme.height ? props.theme.height + 'px' : '150px'};
`
export const TimerSvg = styled.svg`
  transform: scaleX(-1);
`

export const TimerG = styled.g`
  fill: none;
  stroke: none;
`

export const TimerCircle = styled.circle`
  stroke-width: ${props =>
    props.theme.pathWidth ? props.theme.pathWidth + 'px' : '7px'};
  stroke: ${props => props.theme.backgroundPathColor ?? 'gray'};
  opacity: 0.3;
`

export const TimerPath = styled.path`
  stroke-width: ${props =>
    props.theme.pathWidth ? props.theme.pathWidth + 'px' : '7px'};
  stroke-linecap: round;
  transform: rotate(90deg);
  transform-origin: center;
  transition: 1s linear all;
  fill-rule: nonzero;
  stroke: ${props => props.theme.runStrokeColor ?? 'black'};
`

export const TimerLabel = styled.span`
  position: absolute;
  width: ${props => (props.theme.width ? props.theme.width + 'px' : '150px')};
  height: ${props =>
    props.theme.height ? props.theme.height + 'px' : '150px'};
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${props =>
    props.theme.fontSize ? props.theme.fontSize + 'px' : '40px'};
  background: transparent;
  color: ${props => props.theme.fontColor ?? 'black'};
`
export const TimerLogoImage = styled.img`
  width: ${props =>
    props.theme.logoImageWidth ? props.theme.logoImageWidth + 'px' : '50px'};
  height: ${props =>
    props.theme.logoImageHeight ? props.theme.logoImageHeight + 'px' : '50px'};
`

export const TimerBottomText = styled.div`
  font-weight: 600;
  font-size: ${props =>
    props.theme.fontSize ? props.theme.fontSize + 'px' : '30px'};
  color: ${props => props.theme.fontColor ?? 'black'};
  direction: ltr;
`
