export const translations = {
  he: {
    controls: {
      play: 'נגן',
      pause: 'עצור',
      share: 'שתף',
      language: 'שפה',
      settings: 'הגדרות',
      fullscreen: 'מסך מלא',
      rewind: "10 ש' אחורה",
      forward: "10 ש' קדימה",
      vrStereo: 'vrStereo',
      live: 'Live',
      mute: 'השתק',
      unmute: 'בטל השתקה',
      next: 'הבא',
      prev: 'קודם',
      startOver: 'נגן שוב',
      pictureInPicture: 'נגן צף',
      pictureInPictureExit: 'יציאה מנגן צף',
      logo: 'לוגו',
      seekBarSlider: 'סרגל התקדמות וידאו',
    },
    unmute: {
      unmute: 'בטל השתקה',
    },
    copy: {
      button: 'העתק',
    },
    settings: {
      quality: 'איכות',
      speed: 'מהירות',
    },

    share: {
      title: 'שתף',
      link_options: 'לינק',
    },
    overlay: {
      close: 'סגור',
    },
    error: {
      default_error: 'אופס, משהו השתבש',
      default_session_text: 'מזהה',
      retry: 'נסה בשנית',
    },
    ads: {
      ad_notice: '',
      learn_more: 'למידע נוסף',
      skip_ad: 'דלג על פרסומת',
      skip_in: 'דלג',
    },
    pictureInPicture: {
      overlay_text: 'מנגן במצב דסקטופ',
    },
    cast: {
      play_on_tv: 'שתף מסך',
      disconnect_from_tv: 'בטל שיתוף מסף',
      status: {
        connecting_to: 'מחובר ל',
        connected_to: 'מנותק מ',
        playing_on: 'מנגן ב',
      },
    },
  },
}
