import { useRouter } from 'next/router'
import { clientConfig } from '../../../client/clientConfig'
import { useRef } from 'react'
import styled from 'styled-components'
import { config } from '../../../lib'

const FakePlayButton = styled.div`
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 108px;
  width: 108px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  background-color: rgba(0, 0, 0, 0.5);
  transform: translate(-50%, -50%);
  border-radius: 54px;
  padding: 20px;
  cursor: pointer;

  &:hover {
    border: 2px solid rgba(255, 255, 255, 0.4);
  }

  i {
    &:hover {
      filter: brightness(0.5) sepia(1) saturate(10000%);
    }
  }
`
export const RegistrationFormCTA = ({ title }: { title: string }) => {
  const router = useRouter()

  const buttonRef = useRef<HTMLDivElement>(null)
  const formRef = useRef<HTMLFormElement>(null)

  const submit = () => {
    formRef.current && formRef.current.submit()
  }

  return (
    <form
      // target={'_blank'}
      action={
        clientConfig.login_page ||
        config.login_page ||
        'https://13tv.co.il/ott/sso/login/'
      }
      method={'post'}
      ref={formRef}
    >
      <input type={'hidden'} value={router.asPath.split('?')[0]} name={'url'} />
      <input type={'hidden'} value={title} name={'title'} />
      <input type={'hidden'} value={'register'} name={'action'} />
      <FakePlayButton ref={buttonRef} onClick={submit}>
        <i className='playkit-icon playkit-icon-play' />
      </FakePlayButton>
    </form>
  )
}
