export const ReplayIcon = ({ size }: { size: 'big' | 'small' }) => {
  return (
    <svg
      width={size === 'big' ? 47 : 20}
      height={size === 'big' ? 42 : 20}
      viewBox='0 0 47 42'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.71049 15.5775C8.10752 6.61832 16.2933 1.13023e-06 25.9984 1.97868e-06C37.5788 2.99107e-06 47 9.41988 47 21.0004C47 32.5808 37.5796 42 25.9984 42C20.7056 42 15.8674 40.0293 12.1714 36.7866L15.4663 31.0793C18.124 33.8545 21.8613 35.5885 25.9991 35.5885C34.0433 35.5885 40.5876 29.0441 40.5876 21.0008C40.5876 12.9571 34.0436 6.4132 25.9991 6.4132C19.8711 6.4132 14.6161 10.213 12.4597 15.5787L17.4767 15.5787L8.73813 30.7114L-1.50477e-06 15.5775L5.71049 15.5775Z'
        fill='black'
      />
      <path
        d='M5.71049 15.5775C8.10752 6.61832 16.2933 1.13023e-06 25.9984 1.97868e-06C37.5788 2.99107e-06 47 9.41988 47 21.0004C47 32.5808 37.5796 42 25.9984 42C20.7056 42 15.8674 40.0293 12.1714 36.7866L15.4663 31.0793C18.124 33.8545 21.8613 35.5885 25.9991 35.5885C34.0433 35.5885 40.5876 29.0441 40.5876 21.0008C40.5876 12.9571 34.0436 6.4132 25.9991 6.4132C19.8711 6.4132 14.6161 10.213 12.4597 15.5787L17.4767 15.5787L8.73813 30.7114L-1.50477e-06 15.5775L5.71049 15.5775Z'
        fill='white'
      />
    </svg>
  )
}
