import { ReactElement, useEffect, useState } from 'react'
import { utils } from '../Helpers/utils'
import { toggleGeoLocation } from '../Helpers/useGeoPluginFetch'
import styled from 'styled-components'
import { colors } from '../../../styles/GlobalVariables'

const StyledDiv = styled.div`
  padding: 5px 6px;
  background-color: ${colors.backgroundGray};
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid ${colors.darkGray2};
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
const GeoTestButton = ({ country, kid }: { country: string; kid: string }) => {
  const [geoTestButton, setGeoTestButton] = useState<ReactElement | null>(null)

  useEffect(() => {
    setGeoTestButton(
      utils.getParameterByName('geo') === 'test' ? (
        <StyledDiv onClick={() => toggleGeoLocation()}>
          <span>{country}</span>
          <span>{kid}</span>
        </StyledDiv>
      ) : null
    )
  }, [country, kid])

  return geoTestButton
}
export default GeoTestButton
